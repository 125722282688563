@font-face {
  font-family: "BlackHanSans-Regular";
  font-weight: normal;
  src: url("./fonts/BlackHanSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansKR-Black";
  font-weight: normal;
  src: url("./fonts/NotoSansKR-Black.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'NotoSansKR-Black','BlackHanSans-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


