/* ScrollableContainer.css */
.scrollable-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    border: 1px solid #ccc;
    width: 600px; /* 고정된 너비 설정 */
  }
  
  .canvas-item {
    flex: 0 0 auto;
    margin-right: 10px;
  }
  